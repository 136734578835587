import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import Selectors from "../../Selectors";
import Login from "../Login";
import * as Widget from "../Widget";

class AddToCartButton extends Component {
  state = {
    showLoginDialog: false,
    loading: false,
    pathname: ""
  };

  componentDidMount() {
    let pathname = window.location.pathname + window.location.search;
    this.setState({ pathname });
  }

  render() {
    let { profile, item, cartData } = this.props;
    let { showLoginDialog, loading, pathname } = this.state;
    return (
      <Wrapper>
        {this._isItemInCart() ? (
          <Widget.LoadingWrapper loading={loading} spinnerSize={30}>
            <button onClick={() => this.removeItemFromCart()}>
              從購物車移除
            </button>
          </Widget.LoadingWrapper>
        ) : (
          <Widget.LoadingWrapper loading={loading} spinnerSize={30}>
            <button onClick={() => this._addItemToCart()}>加入購物車</button>
          </Widget.LoadingWrapper>
        )}
        {showLoginDialog && (
          <Login.Dialog
            pathname={pathname}
            onClose={() => this.setState({ showLoginDialog: false })}
          />
        )}
      </Wrapper>
    );
  }

  _isItemInCart = () => {
    let { cartData, item } = this.props;

    if (!cartData) {
      return false;
    }
    return !!cartData.items.find(i => i.product.id === item.id);
  };

  removeItemFromCart = () => {
    let { cartData, appActions, item } = this.props;

    let foundIdx = cartData.items.findIndex(i => i.product.id === item.id);
    this.setState({ loading: true });
    appActions
      .removeItemFromCart(foundIdx)
      .catch(err => 0)
      .then(() => this.setState({ loading: false }));
  };

  _addItemToCart = () => {
    let { appActions, navActions, profile, item, quantity } = this.props;

    if (!profile) {
      this.setState({ showLoginDialog: true });
      return;
    }
    this.setState({ loading: true });
    appActions
      .addItemToCart(item.id, JSON.stringify({ quantity }))
      .catch(err => 0)
      .then(() => this.setState({ loading: false }));
  };
}

const Wrapper = styled.div`
  width: 100%;

  & button {
    width: 100%;
    padding: 10px;
    background-color: #0075c1;
    color: white;
    cursor: pointer;
  }

  & button.disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  & button:focus {
    outline: none;
  }

  ${AppConfig["Components/AddToCartButton"].css || ""}
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.getCartData(state)
  }),
  ActionCreator
)(AddToCartButton);
