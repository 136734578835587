const tree = [
  {
    name: "root",
    display: "All",
    children: [
      {
        name: "hotSale",
        display: "熱門商品",
        children: []
      },
      {
        name: "simCard",
        display: "全球上網",
        children: [
          {
            name: "simCard-japan",
            display: "日本",
            children: [
              {
                name: "simCard-japan-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-japan-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-japan-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-japan-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-korea",
            display: "韓國",
            children: [
              {
                name: "simCard-korea-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-korea-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-korea-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-korea-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-china",
            display: "中國",
            children: [
              {
                name: "simCard-china-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-china-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-china-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-china-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-hongkong",
            display: "香港",
            children: [
              {
                name: "simCard-hongkong-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-hongkong-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-hongkong-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-hongkong-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-macao",
            display: "澳門",
            children: [
              {
                name: "simCard-macao-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-macao-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-macao-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-macao-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-thailand",
            display: "泰國",
            children: [
              {
                name: "simCard-thailand-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-thailand-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-thailand-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-thailand-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-vietnam",
            display: "越南",
            children: [
              {
                name: "simCard-vietnam-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-vietnam-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-vietnam-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-vietnam-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-australia",
            display: "澳洲",
            children: [
              {
                name: "simCard-australia-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-australia-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-australia-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-australia-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-america",
            display: "美國",
            children: [
              {
                name: "simCard-america-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-america-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-america-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-america-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-canada",
            display: "加拿大",
            children: [
              {
                name: "simCard-canada-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-canada-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-canada-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-canada-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-philippines",
            display: "菲律賓",
            children: [
              {
                name: "simCard-philippines-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-philippines-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-philippines-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-philippines-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-russia",
            display: "俄羅斯",
            children: [
              {
                name: "simCard-russia-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-russia-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-russia-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-russia-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-singapore",
            display: "新加坡",
            children: [
              {
                name: "simCard-singapore-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-singapore-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-singapore-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-singapore-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-malaysian",
            display: "馬來西亞",
            children: [
              {
                name: "simCard-malaysian-3days",
                display: "3天",
                children: []
              },
              {
                name: "simCard-malaysian-5days",
                display: "5天",
                children: []
              },
              {
                name: "simCard-malaysian-7days",
                display: "7天",
                children: []
              },
              {
                name: "simCard-malaysian-other",
                display: "其他天數",
                children: []
              }
            ]
          },
          {
            name: "simCard-others",
            display: "其他國家",
            children: []
          }
        ]
      },
      {
        name: "travelAttachment",
        display: "旅行小物",
        children: []
      }
    ]
  }
];

module.exports = tree;
exports.default = tree;
