import React, { Component, Fragment } from "react";
import styled from "styled-components";
import * as Icon from "../Icon";
import AppConfig from "../../Config";
import TreeNodeWrapper from "../FilterPanelTreeNode";

const OwnConfig = AppConfig["Components/FilterPanel"];
const themeColor = AppConfig.global.themeColor;

class FilterPanel extends Component {
  render() {
    return (
      <>
        <DesktopFilterPanel {...this.props} />
        <MobileFilterPanel {...this.props} />
      </>
    );
  }
}

const FilterTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: ${themeColor};
  padding: 10px 0;
  ${props => props.css}
`;

class DesktopFilterPanel extends Component {
  render() {
    let { activeFilter, onFilterItemClick, labels } = this.props;
    return (
      <Wrapper>
        <div className="title">分類</div>

        <div className="labels-section">
          {labels.map((label, lidx) => {
            if (label.children.length > 0) {
              return (
                <Fragment key={lidx}>
                  {label.children.map((children, index) => (
                    <TreeNodeWrapper
                      key={index}
                      onNodeClick={node => onFilterItemClick(node)}
                      activeNode={activeFilter}
                      node={children}
                    />
                  ))}
                </Fragment>
              );
            }
          })}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  @media screen and (max-width: ${OwnConfig.breakPointTablet}px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;

  position: sticky;
  top: ${AppConfig.global.navbarHeight}px;
  border: solid 1px ${themeColor};

  & > .labels-section {
    padding: 20px;
  }

  & > .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    background-color: ${themeColor};
    padding: 10px 0;
    ${props => props.css}
  }
`;

class MobileFilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  render() {
    let { activeFilter, onFilterItemClick, labels } = this.props;
    let { visible } = this.state;

    return (
      <MobileWrapper visible={visible}>
        {visible && (
          <MobileBackdrop onClick={() => this.setState({ visible: false })} />
        )}

        <div
          className="ribbon-btn"
          onClick={() => this.setState({ visible: true })}
        >
          <Icon.List size={20} color="#16233D" />
          <span style={{ marginLeft: 5 }}>展開分類</span>
        </div>

        <MobileContainer visible={visible}>
          <FilterTitle css="margin-bottom: 30px; background-color: #eee;">
            <Icon.List color="#16233D" size={26} style={{ marginRight: 10 }} />
            <div style={{ color: "#16233D" }}>分類</div>
          </FilterTitle>

          {labels.map((label, lidx) => {
            if (label.children.length > 0) {
              return (
                <Fragment key={lidx}>
                  {label.children.map((children, index) => (
                    <TreeNodeWrapper
                      key={index}
                      onNodeClick={node => onFilterItemClick(node)}
                      activeNode={activeFilter}
                      node={children}
                    />
                  ))}
                </Fragment>
              );
            }
          })}
        </MobileContainer>
      </MobileWrapper>
    );
  }
}

const MobileWrapper = styled.div`
  @media screen and (min-width: ${OwnConfig.breakPointTablet}px) {
    display: none;
  }

  position: fixed;
  top: 90px;
  left: 0px;
  z-index: ${props => (props.visible ? 100 : 5)};

  & > .ribbon-btn {
    cursor: pointer;
    position: relative;
    padding: 12px 12px 12px 30px;
    background: #eee;
    min-width: 160px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    color: #16233d;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 150px;
      border-top: 35px solid #eee;
      border-right: 35px solid transparent;
    }

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 150px;
      border-right: 35px solid transparent;
      border-bottom: 35px solid #eee;
    }
  }
`;

const MobileBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`;

const MobileContainer = styled.div`
  position: fixed;
  top: 0px;
  left: ${props => (props.visible ? "0px" : "-200px")};
  background-color: #eee;
  width: 200px;
  min-height: 100vh;
  padding: 50px 20px;
  overflow: auto;
  transition: left 200ms;
`;

export default FilterPanel;
