import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import jsonParser from "../../Utils/JsonParser";
import ParseQuery from "../../Utils/ParseQuery";
import ItemBrowser from "../ItemBrowser";
import FilterPanel from "../FilterPanel/Wantcard";
import Paging from "../Paging";
import SortingGroup from "../SortingGroup/Wantcard";
import Breadcrumbs from "../Breadcrumbs/Wantcard";
import Searching from "../Searching/Wantcard";
import CartWidget from "../CartWidget";

const ListingPageFactory = ({
  layoutType = "grid",
  itemLimit = 60,
  cssPrefix = "items",
  css = "",
  Item,
  pathPrefix,
  detailPathPrefix,
  fetchAction,
  labels,
  sortType,
  FilterPanelComp,
  PagingComp,
  SortingGroupComp,
  hasBreadcrumbs = true,
  hasSearching = true,
  BreadcrumbsComp,
  SearchingComp,
  CartWidgetComp
}) => {
  if (!Item) {
    throw "[rev-cms/ListPageFactory] Item not passed, this should be a component";
  }

  if (!pathPrefix) {
    throw '[rev-cms/ListPageFactory] pathPrefix not passed, this should be something like "/products"';
  }

  if (!detailPathPrefix) {
    throw '[rev-cms/ListPageFactory] detailPathPrefix not passed, this should be something like "/product"';
  }

  if (!fetchAction) {
    throw '[rev-cms/ListPageFactory] fetchAction not passed, this should be something like "fetchProducts"';
  }

  if (!labels) {
    throw "[rev-cms/ListPageFactory] labels not passed! please refer to src/Domain/ProductTree for a valid label definition";
  }

  if (!sortType) {
    throw '[rev-cms/ListPageFactory] sortType not passed, this should be something like "products"';
  }

  if (FilterPanelComp === undefined) {
    FilterPanelComp = FilterPanel;
  }

  if (PagingComp === undefined) {
    PagingComp = Paging;
  }

  if (SortingGroupComp === undefined) {
    SortingGroupComp = SortingGroup;
  }

  if (hasBreadcrumbs) {
    BreadcrumbsComp =
      BreadcrumbsComp === undefined ? Breadcrumbs : BreadcrumbsComp;
  }

  if (hasSearching) {
    SearchingComp = SearchingComp === undefined ? Searching : SearchingComp;
  }

  if (CartWidgetComp === undefined) {
    CartWidgetComp = CartWidget;
  }

  class ListingPage extends Component {
    state = {
      searchInput: ""
    };

    componentDidMount() {
      let { location, appActions, navActions } = this.props;
      if (location.search.indexOf("data") > -1) {
        let dataEncode = location.search.slice(location.search.indexOf("data"));
        let queryParams = ParseQuery(dataEncode);
        let data = JSON.parse(decodeURIComponent(queryParams.data));

        if (data.token) {
          window.localStorage.setItem("token", data.token);
          appActions
            .autoLogin()
            .then(() => console.log("social login success"));
          setTimeout(() => navActions.push(location.pathname), 1500);
        }
      }
    }

    render() {
      let { categoryId, appActions, navActions } = this.props;
      let { searchInput } = this.state;

      return (
        <Wrapper>
          <ItemBrowser
            label={categoryId}
            navPush={navActions.push}
            limit={itemLimit}
            fetchItems={appActions[fetchAction]}
            search={searchInput}
          >
            {({ mounted, loading, data, page, sort, search }) => {
              return (
                <>
                  <div className={`${cssPrefix}-top`}>
                    <div className={`${cssPrefix}-filter`}>
                      <FilterPanel
                        labels={labels}
                        activeFilter={categoryId}
                        onFilterItemClick={updateLabel =>
                          navActions.push(`${pathPrefix}/${updateLabel}`)
                        }
                      />
                    </div>

                    <div className={`${cssPrefix}-search`}>
                      {SearchingComp && (
                        <div className={cssPrefix + "-search"}>
                          <Searching
                            onSubmit={searchInput =>
                              this.setState({ searchInput })
                            }
                            search={search}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={`${cssPrefix}-container`}>
                    <div className={`${cssPrefix}-right`}>
                      {SearchingComp && (
                        <div className={cssPrefix + "-search"}>
                          <Searching
                            onSubmit={searchInput =>
                              this.setState({ searchInput })
                            }
                            search={search}
                          />
                        </div>
                      )}
                      {CartWidgetComp && (
                        <div className={`${cssPrefix}-cart`}>
                          <CartWidgetComp />
                        </div>
                      )}
                      <div className={`${cssPrefix}-filter`}>
                        <FilterPanel
                          labels={labels}
                          activeFilter={categoryId}
                          onFilterItemClick={updateLabel =>
                            navActions.push(`${pathPrefix}/${updateLabel}`)
                          }
                        />
                      </div>
                    </div>
                    <div className={`${cssPrefix}-content`}>
                      <div className={cssPrefix + "-toolbar"}>
                        {BreadcrumbsComp && (
                          <div className={cssPrefix + "-breadcrumbs"}>
                            <BreadcrumbsComp
                              pathPrefix={pathPrefix}
                              label={categoryId}
                            />
                          </div>
                        )}
                        {SortingGroupComp && sort && (
                          <div className={`${cssPrefix}-sort`}>
                            <SortingGroupComp sort={sort} type={sortType} />
                          </div>
                        )}
                      </div>

                      <div className={`${cssPrefix}-collections`}>
                        {data &&
                          data.map(d => (
                            <Item
                              onClick={() =>
                                navActions.push(
                                  `${detailPathPrefix}/?id=${d.id}`
                                )
                              }
                              key={d.id}
                              item={d}
                            />
                          ))}
                      </div>

                      {PagingComp && page.pages && (
                        <div className={cssPrefix + "-pager"}>
                          <PagingComp page={page} />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            }}
          </ItemBrowser>
        </Wrapper>
      );
    }
  }

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 5%;

    & > .${cssPrefix}-top {
      display: none;
    }

    & > .${cssPrefix}-container {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      & > .${cssPrefix}-right {
        max-width: 300px;
        width: 100%;

        & .${cssPrefix}-search {
          margin-bottom: 30px;
        }

        & .${cssPrefix}-cart {
          margin-bottom: 30px;
        }

        & .${cssPrefix}-filter {
        }
      }

      & > .${cssPrefix}-content {
        flex: 1;
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        & .${cssPrefix}-toolbar {
          display: flex;
          justify-content: space-between;
          padding: 0px 20px;

          & .${cssPrefix}-breadcrumbs {
            margin-bottom: 20px;
          }
          & .${cssPrefix}-sort {
            margin-bottom: 20px;
            max-width: 166px;
            width: 100%;
          }
        }

        & .${cssPrefix}-collections {
          ${layoutType === "grid" &&
            `
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
          `}
        }

        & .${cssPrefix}-pager {
        }
      }
    }

    @media screen and (max-width: 1280px) {
      padding: 110px 0px 60px 0px;
      & > .${cssPrefix}-top {
        display: block;
        margin-bottom: 20px;
        padding: 0px 10px;

        & .${cssPrefix}-filter {
        }

        & .${cssPrefix}-search {
          max-width: 330px;
        }
      }

      & > .${cssPrefix}-container {
        & > .${cssPrefix}-right {
          display: none;
        }

        & > .${cssPrefix}-content {
          & > .${cssPrefix}-toolbar {
            padding: 0px;
          }
        }
      }
    }

    @media screen and (max-width: 599px) {
      & > .${cssPrefix}-container {
        & > .${cssPrefix}-content {
          & > .${cssPrefix}-toolbar {
            flex-direction: column;
          }
        }
      }
    }
  `;

  return withPage(
    connect(
      (state, ownProps) => ({
        categoryId: ownProps.pageContext.categoryId
      }),
      ActionCreator
    )(ListingPage)
  );
};

export default ListingPageFactory;
