import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import categoryTree from "../../Domain/ProductTree";
import * as Widget from "../Widget2";
import * as Icon from "../Icon";

function getDisplay(node, label) {
  if (node.name === label) {
    return node.display || node.name;
  }

  if (node.children) {
    for (let subtreeNode of node.children) {
      let display = getDisplay(subtreeNode, label);
      if (display) {
        return display;
      }
    }
  }
}

function splitLabel(label) {
  let result = [];
  let accLabel = "";
  for (let c of label) {
    if (c === "-") {
      result.push(accLabel);
    }
    accLabel += c;
  }
  result.push(accLabel);
  return result;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > a {
    display: flex;
    align-items: center;

    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    color: #16233d;
    cursor: pointer;
  }

  & > a::after {
    content: ">";
    margin: 0 10px;
  }
`;

class BreadCrumbs extends React.Component {
  render() {
    let { label, pathPrefix } = this.props;
    if (label === "root" || label === "") {
      return (
        <Wrapper>
          <Link to={`${pathPrefix}`}>
            <Icon.Location size={20} color="#99BED8" />
            <span style={{ marginLeft: 5 }}>全部</span>
          </Link>
        </Wrapper>
      );
    }

    let crumbs = splitLabel(label).map(name => ({
      name,
      display: getDisplay(categoryTree[0], name)
    }));

    return (
      <Wrapper>
        <Link to={`${pathPrefix}`}>
          <Icon.Location size={20} color="#99BED8" />
          <span style={{ marginLeft: 5 }}>全部</span>
        </Link>

        {crumbs.map(crumb => (
          <Link key={crumb.name} to={`${pathPrefix}/${crumb.name}`}>
            {crumb.display}
          </Link>
        ))}
      </Wrapper>
    );
  }
}

export default BreadCrumbs;
