import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import AppConfig from "../Config";
import * as Widget from "./Widget2";
import * as Icon from "./Icon";
import CartItem from "./CartWidgetItem";

const themeColor = AppConfig.global.themeColor;

class CartWidget extends React.Component {
  render() {
    let { cartData, navActions } = this.props;

    return (
      <Wrapper>
        <FilterTitle>
          <div>你的購物車</div>
        </FilterTitle>
        <div className="content">
          {(() => {
            if (!cartData || cartData.items.length === 0) {
              return (
                <EmptyCart>
                  <img
                    src="../../images/empty-cart.png"
                    style={{ width: 80, height: 80, margin: 5 }}
                  />
                  沒有任何商品
                </EmptyCart>
              );
            } else {
              return (
                <div className="items">
                  {cartData.items.map((item, i) => (
                    <CartItem
                      key={i}
                      item={item}
                      css={`
                        margin-bottom: 20px;
                        &:last-child {
                          margin-bottom: 0px;
                        }
                      `}
                    />
                  ))}
                </div>
              );
            }
          })()}
        </div>

        <PaymentButton onClick={() => navActions.push("/cart")}>
          結帳去
          <Icon.ArrowForward
            size={24}
            color={themeColor}
            css="margin-left:5px;"
          />
        </PaymentButton>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border: 1px solid ${themeColor};
  width: 100%;

  & > .content {
    height: 150px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    padding: 20px;

    & .items {
    }
  }
`;

const FilterTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: white;
  background-color: ${themeColor};

  padding: 10px 0;
  ${props => props.css}
`;

const EmptyCart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${themeColor};
`;

const PaymentButton = styled.div`
  cursor: pointer;
  border-top: solid 1px ${themeColor};
  color: ${themeColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &:hover {
    background-color: #eee;
  }
`;

export default connect(
  (state, ownProps) => ({
    cartData: Selectors.getCartData(state)
  }),
  ActionCreator
)(CartWidget);
