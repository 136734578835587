import React, { Component, Fragment } from "react";
import styled from "styled-components";
import AppConfig from "../../Config";
import SortOption from "../SortOption";
import * as Icon from "../Icon";

const moduleConfig = AppConfig["Component/SortingGroup"];
const themeColor = AppConfig.global.themeColor;

class SortingGroup extends Component {
  render() {
    let { sort, type } = this.props;
    let options = moduleConfig[`${type}Options`];
    let selectOpt = options.find(option => option.value === sort.value);
    return (
      <Wrapper>
        <select onChange={this._onChange}>
          <option key={-1} value={null} disabled selected>
            排列方式
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Wrapper>
    );
  }
  _onChange = event => {
    let { sort } = this.props;
    sort.go(event.target.value);
  };
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  border: solid 1px ${themeColor};
  padding: 5px 8px;
  width: 100%;
  min-height: 30px;

  & > select {
    color: ${themeColor};
    font-size: 0.8em;
    border: 0;
    outline: 0;
    background: transparent;
    width: 100%;
  }

  ${AppConfig["Component/SortingGroup"].css || ""}
`;

export default SortingGroup;
