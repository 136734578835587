import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import categoryTree from "../../Domain/ProductTree";
import * as Icon from "../Icon";
import AppConfig from "../../Config";

const themeColor = AppConfig.global.themeColor;

const Wrapper = styled.div`
  display: flex;
  height: 30px;
  border: 1px solid ${themeColor};
  padding: 5px 0 5px 8px;
  width: 100%;
`;

const SearchButton = styled.div`
  cursor: pointer;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  color: ${props => (props.selected ? "themeColor" : "#99BED8")};
  border-left: solid 1px ${themeColor};
  :hover {
    color: ${themeColor};
  }
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  color: ${themeColor};
  flex: 1;
  &::placeholder {
    color: #99bed8;
  }
`;

class Searching extends React.Component {
  state = {
    searchInput: ""
  };
  render() {
    let { searchInput } = this.state;
    let { onSubmit, search } = this.props;
    return (
      <Wrapper>
        <SearchInput
          type="text"
          placeholder="搜尋"
          onChange={e => this.setState({ searchInput: e.target.value })}
        />
        <SearchButton
          onClick={() => {
            search.go(searchInput);
            onSubmit(searchInput);
          }}
        >
          <Icon.Search size={20} color={themeColor} />
        </SearchButton>
      </Wrapper>
    );
  }
}

export default Searching;
