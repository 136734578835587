import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../Domain/constants";

class CartItem extends Component {
  render() {
    let { item, css } = this.props;

    return (
      <Wrapper css={css}>
        <Image
          src={`${Constants.apiUrl}${item.product.image}`}
          css="margin-right: 16px;"
        />
        <div className="text-container">
          <div className="text">{item.product.name}</div>
          <div className="text">數量 - {item.config.quantity} 個</div>
          <div className="text">
            金額 - {item.config.quantity * item.product.price} 元
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;

  & .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .text {
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    color: #16233d;
  }

  ${props => props.css || ""};
`;

const Image = styled.div`
  width: 70px;
  height: 70px;

  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: lightgray;

  ${props => props.css || ""};
`;

export default CartItem;
