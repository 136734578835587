import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Constants from "../../Domain/constants";
import ActionCreator from "../../ActionCreator";
import BaseGridItem from "../BaseGridItem";
import * as Widget from "../Widget2";
import AddToCartButton from "../AddToCartButton";

class ProductGridItem extends Component {
  render() {
    let { item, css, navActions } = this.props;

    return (
      <Wrapper sale={item.special_price !== 0} extraHeight={140} css={css}>
        <Image
          src={`${Constants.apiUrl}${item.image}`}
          css="margin-bottom: 16px;"
          onClick={() => navActions.push(`/product/?id=${item.id}`)}
        />
        <div
          className="text-container"
          onClick={() => navActions.push(`/product/?id=${item.id}`)}
        >
          <div className="title">{item.name}</div>
          <Widget.Row
            align="center"
            justify="center"
            css="margin-bottom: 12px;"
          >
            {item.original_price !== 0 && (
              <div className="special-price">${item.original_price}</div>
            )}
            <div className="price">${item.price}</div>
          </Widget.Row>
        </div>

        <div className="button-container">
          <AddToCartButton item={item} quantity={1} />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled(BaseGridItem)`
  cursor: pointer;

  & > .text-container {
    & .title {
      margin-bottom: 9px;
      letter-spacing: 1px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #16233d;
    }

    & .special-price {
      margin-right: 9px;
      text-decoration: line-through;
      letter-spacing: 1px;
      font-size: 14px;
      color: #b4b9c4;
    }

    & .price {
      letter-spacing: 1px;
      font-size: 14px;
      color: ${props => (props.sale ? "#D0021B" : "#16233D")};
    }
  }

  & > .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${props => props.css || ""};
`;

const Image = styled.div`
  width: 100%;
  height: calc(100% - 140px);

  background-color: lightgray;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${props => props.css || ""}
`;

export default connect(
  null,
  ActionCreator
)(ProductGridItem);
